// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cancelled-js": () => import("./../../../src/pages/cancelled.js" /* webpackChunkName: "component---src-pages-cancelled-js" */),
  "component---src-pages-concepts-js": () => import("./../../../src/pages/concepts.js" /* webpackChunkName: "component---src-pages-concepts-js" */),
  "component---src-pages-curations-js": () => import("./../../../src/pages/curations.js" /* webpackChunkName: "component---src-pages-curations-js" */),
  "component---src-pages-furniture-js": () => import("./../../../src/pages/furniture.js" /* webpackChunkName: "component---src-pages-furniture-js" */),
  "component---src-pages-goods-js": () => import("./../../../src/pages/goods.js" /* webpackChunkName: "component---src-pages-goods-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-templates-blog-details-js": () => import("./../../../src/templates/blogDetails.js" /* webpackChunkName: "component---src-templates-blog-details-js" */),
  "component---src-templates-concept-details-js": () => import("./../../../src/templates/conceptDetails.js" /* webpackChunkName: "component---src-templates-concept-details-js" */),
  "component---src-templates-store-details-js": () => import("./../../../src/templates/storeDetails.js" /* webpackChunkName: "component---src-templates-store-details-js" */)
}

